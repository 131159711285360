/* Loading spinner */
.spinner {
    position: absolute;
    left: calc(50% - 100px);
    margin-top: 10px;
}
.spinner.vt-center {
    top: calc(50% - 15px);
}

.dropdown-hr-wrapper {
    margin-left: 5px;
    margin-right: 5px;
}
hr {
    border-top: 2px solid #AAA;
}
.bold {
    font-weight: bold;
}

.justify-left {
    text-align: left;
}

/* App Menu */
#AppSidebar, #UserProfile {
    display: flex;
    flex-direction: column;
    float: left;
    min-width: 100px;
    max-width: 100px;
    height: 100%;
    background: #f0f0f0;
}
#UserProfile {
    padding-top: 6px;
}

/* Components */
#ClosePopup {
    position: absolute;
    right: 10px;
}
#LoginForm .remember {
    text-align: left;
}

.app-title {
    position: absolute;
    display: inline-block;
    margin-left: 8px;
    top: 32px;
    color: var(--client-fnt-color);
}
.app-subtitle {
    font-size: 24px;
    color: var(--client-btn-color);
}
.app-version {
    position: absolute;
    display: inline-block;
    top: 65px;
    right: 44px;
    color: #b0b0b0;
    font-size: 12px;
}
.client-title {
    position: absolute;
    top: 44px;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.form-logo {
    height: 80px;
    width: auto;
}
div.module-logo {
    width: 16px;
    height: 16px;
    display: inline-block;
    background-color: rgba(15, 108, 191, .8);
    border-radius: 4px;
    margin-right: 3px;
}
div.module-logo.quiz {
    background-color: rgba(235, 102, 162, .8);
}
img.module-logo {
    height: 14px;
    width: auto;
    margin: 1px;
    filter: brightness(0) invert(1);
}
.padding {
    padding-left: 8px;
    padding-right: 8px;
}
.info-icon {
    margin-top: 2px;
    margin-right: 8px;
    display: inline-block;
    float: right;
    color: var(--client-btn-color);
    cursor: pointer;
}
.nav-icon-wrapper {
    display: inline-block;
    margin-right: 4px;
}
.nav-icon {
    margin-right: 6px;
}

.help-title {
    color: var(--client-btn-color);
}
.help-text {
    font-size: 12px;
    text-align: center;
    color: var(--client-btn-color);
}
#lct-engagement-tooltip {
    font-size: 12px;
    color: white;
    background: var(--client-btn-color);
    z-index: 10;
}

.navigate-main {
    float: right;
    top: 0;
    right: 20px;
    display: inline-block;
}
.navigate-sub {
    position: absolute;
    top: 12px;
    right: 50px;
    display: inline-block;
}
.navigate-engagement,
.navigate-search,
.navigate-report,
.navigate-refresh,
.navigate-survey,
.navigate-export,
.navigate-issue {
    float: right;
    margin-right: 15px;
}
.navigate-report {
    margin-right: 15px;
}
.navigate-search {
    text-align: left;
    color: var(--client-btn-color);
    border: 1px solid var(--client-btn-color);
    border-radius: 0.25rem;
    font-size: 0.875rem;
    padding: 4px 8px 4px 8px;
    width: 250px;
}
.navigate-search ::placeholder {
    color: var(--client-btn-color);
    opacity: 1; /* Firefox */
}
.navigate-search input {
    line-height: 19px;
    width: calc(100% - 10px);
    color: var(--client-btn-color);
    border: none;
}
.navigate-search input:focus {
    outline: none
}
.navigate-search .clearsearch:after {
    cursor: pointer;
    content: 'X';
}

.section-reload {
    margin-top: -20px;
    margin-left: 5px;
    color: var(--client-clr-warning);
}
.section-reload:hover {
    color: var(--client-clr-selected);
}
.reload {
    float: right;
}
th.sort-asc,
th.sort-desc {
    cursor: pointer;
}
th.sort-asc.active,
th.sort-desc.active {
    color: #357a32;
}
th.sort-asc::before {
    position: absolute;
    top: -2px;
    right: 2px;
    content: "\25B5";
    font-size: 10px;
}
th.sort-asc.active::before {
    top: -2px;
    content: "\25B2";
}
th.sort-desc::before {
    position: absolute;
    top: -2px;
    right: 2px;
    content: "\25BD";
    font-size: 10px;
}
th.sort-desc.active::before {
    top: -1px;
    font-size: 9px;
    content: "\25BC";
}

.search {
    border: none;
}
.selectable {
    cursor: pointer;
}
td.value.text-success,
td.value.text-danger {
    padding-top: 6px;
    font-size: 22px;
}
div.sub-description,
td.value.text-success {
    padding-top: 2px;
    padding-left: 6px;
    color: #357a32!important;
}
td.value.text-danger {
    color: #ca3120!important;
}

.survey-timeframe {
    position: absolute;
    right: calc(50% + 20px);
}
.timeframes {
    float: right;
    right: 20px;
    margin-top: -3px;
    margin-left: 20px;
}
.timeframes span {
    color: #999;
    display: inline-block;
    float: right;
}
.timeframes .dropdown-section {
    border-top: 1px solid #aaa;
}
.timeframes .dropdown-toggle {
    padding: 3px 10px 3px 10px;
    font-family: var(--bs-btn-font-family);
    font-size: 14px;
    line-height: 24px;
}
.timeframes .dropdown-menu {
    width: 280px;
    padding: 2px 2px 10px 2px;
}

.table-striped > tbody > tr#census:nth-of-type(odd) > *,
.table-striped > tbody > tr#census:nth-of-type(even) > *,
.table-striped > tbody > tr#utilization:nth-of-type(odd) > *,
.table-striped > tbody > tr#utilization:nth-of-type(even) > * {
    --bs-table-accent-bg: rgba(204, 229, 255, 1);
    background-color: rgba(204, 229, 255, 1);
}
.table-striped > tbody > tr#care:nth-of-type(odd) > *,
.table-striped > tbody > tr#care:nth-of-type(even) > * {
    --bs-table-accent-bg: rgba(230, 242, 255, 1);
    background-color: rgba(230, 242, 255, 1);
}
.table-striped > tbody > tr.selected#care:nth-of-type(odd) > *,
.table-striped > tbody > tr.selected#care:nth-of-type(even) > *,
.table-striped > tbody > tr.selected#census:nth-of-type(odd) > *,
.table-striped > tbody > tr.selected#census:nth-of-type(even) > * {
    --bs-table-accent-bg: var(--client-clr-selected);
    background-color: var(--client-clr-selected);
}

#census td,
#utilization td {
    font-size: 15px;
    font-weight: bold;
}

.toast {
    position: absolute;
    left: 50%;
    top: 40%;
    -ms-transform: translate(-50%, -40%);
    transform: translate(-50%, -40%);
    z-index: 10;
}
.toast-header {
    color: white;
    background-color: var(--client-header-color);
}
.toast-body {
    text-align: left;
    background-color: white;
    border-radius: 5px;
}

/* Tooltips */
.tip-custom {
    padding: 0 10px;
}
.tip-title {
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
}
.tip-last-year {
    color: #31a5a4;
    margin: 0;
    padding: 0;
}
.tip-this-year {
    color: #3399ff;
    margin: 0;
    padding: 0;
}

/* category */
.category {
    margin-right: 6px;
}
.category.addiction {
    color: #800000;
}
.category.care_initiative {
    color: #FF7F3F;
}
.category.culture {
    color: #FF0000;
}
.category.emotional_health {
    color: #FF00FF;
}
.category.first_responders {
    color: #0000FF;
}
.category.manager_training {
    color: #FFFF00;
}
.category.wellness {
    color: #008080;
}
.category.post {
    color: #00FF00;
}
.category.story {
    color: #00FFFF;
}

/* React-Bootstrap button style changes */
.btn-pointer {
    cursor: pointer;
}
.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--client-btn-color);
    --bs-btn-border-color: var(--client-btn-color);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--client-btn-hover);
    --bs-btn-hover-border-color: var(--client-btn-color);
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--client-btn-color);
    --bs-btn-active-border-color: var(--client-btn-color);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: var(--client-btn-color);
    --bs-btn-disabled-border-color: var(--client-btn-color);
}
.btn-secondary {
    --bs-btn-color: var(--client-btn-color);
    --bs-btn-bg: #fff;
    --bs-btn-border-color: var(--client-btn-color);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--client-btn-hover);
    --bs-btn-hover-border-color: var(--client-btn-color);
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--client-btn-color);
    --bs-btn-active-border-color: var(--client-btn-color);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: var(--client-btn-color);
    --bs-btn-disabled-border-color: var(--client-btn-color);
}
.btn-forgot {
    --bs-btn-color: var(--client-btn-color);
    --bs-btn-bg: #FFF;
    --bs-btn-border-color: #FFF;
    --bs-btn-hover-color: var(--client-btn-hover);
    --bs-btn-hover-bg: #f9f7fc;
    --bs-btn-hover-border-color: #f0f0f0;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #f0f0f0;
    --bs-btn-active-border-color: #f0f0f0;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #f0f0f0;
    --bs-btn-disabled-border-color: #f0f0f0;
}
