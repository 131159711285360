/* Form styles */
h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
    margin-bottom: 0;
}

.iframe-popup {
    width: 100%;
    height: 100%;
}

/* Block (section) styles */
.section-group {
    flex: 1 1;
}
.section {
    position: relative;
    margin: 15px 0 0 0;
}
.section-header {
    color: var(--client-btn-color);
    font-size: 22px;
    text-align: left;
    padding-left: 20px;
}
.section-icon {
    margin-top: -4px;
    margin-right: 8px;
}

.sidebar-icon-wrapper {
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;
    color: var(--client-btn-inactive);
}
.sidebar-icon-wrapper.static,
.sidebar-icon-wrapper.selected {
    font-weight: bold;
    color: var(--client-btn-color);
}
.sidebar-icon {
    margin-left: auto;
    margin-right: auto;
}
.sidebar-icon-wrapper .show:after {
    position: absolute;
    right: 12px;
    top: 8px;
    width: 8px;
    height: 8px;
    content: "+";
    font-size: 18px;
    color: var(--client-btn-inactive);
}
.sidebar-icon-wrapper .hide {
    position: absolute;
    right: 12px;
    top: 20px;
    width: 8px;
    height: 8px;
    transition: .5s;
    box-shadow: -2px 2px 0 var(--client-btn-color);
    transform: rotate(-135deg);
}
.sidebar-icon-wrapper:hover {
    background: var(--client-btn-color);
    color: #fff;
}
.sidebar-icon-wrapper:hover .show:after,
.sidebar-icon-wrapper:hover .hide:after {
    color: #fff;
}

.search-icon {
    padding-top: 6px;
}

.menu-icon-wrapper {
    color: var(--client-btn-color);
    cursor: pointer;
}
.menu-icon-wrapper .down-arrow {
    position: absolute;
    right: -6px;
    top: 14px;
    width: 8px;
    height: 8px;
    transition: .5s;
    box-shadow: -2px 2px 0 var(--client-btn-color);
    transform: rotate(-45deg);
}
.menu-icon-wrapper:hover {
    color: var(--client-btn-hover);
}
.menu-icon-wrapper:hover .down-arrow {
    box-shadow: -2px 2px 0 var(--client-btn-hover);
}

.popup-icon-wrapper {
    display: flex;
    flex-direction: row;
    margin: 5px;
    cursor: pointer;
}
.popup-icon-text {
    margin-left: 8px;
}

/* Table style changes */
table {
    table-layout: fixed;
}
thead {
    background-color: var(--client-thead-color);
}
tfoot {
    font-weight: bold;
}

.table,
.table > :not(caption) > * > * {
    padding: 0.1rem 0.3rem;
    font-size: 13px;
}
.warning,
.table-striped > tbody > tr:nth-of-type(odd) > .warning,
.table-striped > tbody > tr:nth-of-type(even) > .warning {
    color: darkred;
}

/* NPS results */
.table-striped > tbody > tr > .detractor {
    color: darkred;
    background-color: rgba(139, 0, 0, .1);
}
.table-striped > tbody > tr > .promoter {
    color: darkgreen;
    background-color: rgba(0, 100, 0, .1);
}

.table .description,
.table .company,
.table .location,
.table .grouping {
    text-align: left;
}
tr.description,
td.description {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.table .location,
.table .grouping {
    border-top: 2px solid var(--client-btn-border-color);
    font-size: 15px;
    font-weight: bold;
}
.table .total {
    text-align: right;
}

th.tab, td.tab {
    width: 24px;
}
.tab .field-icon {
    margin-top: 2px;
}
th.index, td.index {
    width: 46px;
    text-align: center;
}
th.date, td.date,
th.value, td.value,
th.coupon, td.coupon {
    width: 75px;
    text-align: center;
}
th.time, td.time {
    width: 110px;
    text-align: center;
}
th.coupon, td.coupon {
    font-size: 12px;
}
th.timestamp, td.timestamp {
    width: 180px;
    text-align: center;
}
.table .percent {
    width: 56px;
    text-align: center;
}
.table .question,
.table .subject {
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.table .subject {
    width: 250px;
    white-space: break-spaces;
}
.table .course,
.table .user {
    width: 200px;
}
.table .details,
.table .label {
    text-align: left;
}

.table .left-col {
    width: 50%;
    text-align: left;
}
.table .right-col {
    width: 50%;
    text-align: left;
}
div.left-col {
    float: left;
    width: 25%;
    text-align: left;
}
div.right-col {
    float: left;
    padding-top: 20px;
    width: 60%;
    text-align: left;
}

tr {
    cursor: default;
}
tr.selectable,
tr.selected {
    cursor: pointer;
}

th.identifier, td.identifier {
    width: 25px;
}
td.total {
    font-weight: bold;
}
th.month,
td.month {
    width: 6%;
}
td.tags {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}
td.multiline-description {
    text-align: left;
}
td.description.singleline {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

td.description.module-group,
td.description.module {
    font-size: 12px;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}
.module-group td,
.module td {
    font-size: 12px;
    color: #555;
}
.module-group td.description {
    padding-left: 15px;
}
.module td.description {
    padding-left: 30px;
}
tr.course td {
    border-top: 1px solid var(--client-btn-border-color);
    border-bottom: 1px solid var(--client-btn-border-color);
}

th.edit, td.edit {
    width: 20px;
}
th.engagement, td.engagement,
th.label, td.label,
th.total, td.total {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}
th.company, td.company,
th.category, td.category,
th.phone, td.phone {
    width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}
th.tags, td.tags {
    width: 18%;
}
th.type, td.type,
th.outcome, td.outcome {
    width: 150px;
}
th.ticket, td.ticket,
th.status, td.status,
th.priority, td.priority {
    width: 65px;
}
th.student, td.student {
    width: 260px;
}
th.tags, td.tags,
th.summary, td.summary,
th.student, td.student,
th.email, td.email,
th.response, td.response {
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
th.subcategory, td.subcategory {
    text-align: left;
    font-weight: bold;
}

.table-striped > tbody > tr:nth-of-type(odd) > td.missing,
.table-striped > tbody > tr:nth-of-type(even) > td.missing {
    color: red;
}
.table-striped > tbody > tr:nth-of-type(odd) > td.link,
.table-striped > tbody > tr:nth-of-type(even) > td.link {
    color: #0f6fc5;
    text-decoration: underline;
}
th.highlight,
td.highlight,
.table-striped > tbody > tr:nth-of-type(odd) > td.highlight,
.table-striped > tbody > tr:nth-of-type(even) > td.highlight {
    font-weight: bold;
    --bs-table-accent-bg: rgba(155, 97, 255, 0.05);
    background-color: rgba(155, 97, 255, 0.05);
}
td.selected,
.table-striped > tbody > tr.selected:nth-of-type(odd) > td,
.table-striped > tbody > tr.selected:nth-of-type(even) > td {
    --bs-table-accent-bg: var(--client-clr-selected);
    background-color: var(--client-clr-selected);
}
td.selected,
.table > tbody > tr.selected:nth-of-type(odd) > td,
.table > tbody > tr.selected:nth-of-type(even) > td {
    font-weight: bold;
}

tfoot td.description,
tfoot td.total {
    text-align: right;
    font-weight: bold;
}
tfoot td.value {
    font-weight: bold;
}

/* Table row and column changes */
.row, .block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 10px 0 10px 0;
    width: 100%;
}
.column {
    position: relative;
    width: 50%;
}
.column.bordered {
    width: calc(50% - 22px);
    margin: 10px 5px;
    padding: 5px;
    border: #f0f0f0 1px solid;
}

.dropdown {
    display: inline-block;
}

/* Header layout items */
.clients,
.locations,
.departments {
    padding-left: 10px;
    padding-right: 10px;
}
.questions {
    font-size: 14px;
    line-height: 1.1em;
    text-align: left;
    background: var(--client-thead-color);
    border: #e0e0e0 1px solid;
    margin: 0;
    padding: 5px 0 5px 0;
}
.clients .dropdown-level-0 {
    color: var(--client-btn-color);
    font-size: large;
    font-weight: bold;
}
.clients .dropdown-level-1 {
    line-height: 1.2em;
}
.clients .dropdown-level-2 {
    margin-left: 20px;
    line-height: 1.1em;
}
div.th-row {
    display: block;
}

/* General page layout */
.page {
    display: flex;
    height: 100%;
    flex-direction: column;
}
.header {
    height: 86px;
    text-align: left;
    padding: 2px 30px 2px 30px;
    background-color: #f0f0f0;
    color: white;
    border-bottom: #e0e0e0 1px solid;
}
.subheader {
    position: relative;
    min-height: 56px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: #e0e0e0 1px solid;
}
.subheader-title {
    display: inline-block;
    padding-top: 2px;
    color: var(--client-header-color);
    font-size: 24px;
}
.body {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    overflow: hidden;
}
.scroll-y {
    position: relative;
    height: calc(100% - 50px);
    width: 100%;
    overflow-y: scroll !important;
    overflow-x: hidden;
}
.scroll-y-full {
    position: relative;
    height: calc(100% - 10px);
    width: 100%;
    overflow-y: scroll !important;
    overflow-x: hidden;
}
.footer {
    height: 40px;
    text-align: left;
    background-color: white;
    padding: 8px 30px 0 30px;
    border-top: #e0e0e0 1px solid;
    font-size: 14px;
    color: #120d0d;
    overflow: hidden;
}

.table-summary {
    width: calc(100% - 32px);
    margin-left: 12px;
    margin-right: auto;
    background: var(--client-thead-color);
    border: #e0e0e0 1px solid;
}
.table-summary div {
    margin-left: 12px;
    line-height: 1.0em;
    font-size: 14px;
    text-align: left;
}
div.table-summary .title {
    margin: 6px 2px 2px 2px;
    font-size: 16px;
    font-weight: bold;
}
.table-summary div:last-child {
    margin-bottom: 6px;
}

details {
    position: relative;
    padding-left: 8px;
    padding-right: 8px;
}
details.full-height {
    height: 100%;
}
.company-id {
    padding-left: 6px;
    color: #999;
}

/* Components */
#FreshdeskEngagements,
#CoursesEngagements,
#SupportTickets {
    margin: 10px;
}
#FreshdeskEngagements .table-responsive,
#CoursesEngagements .table-responsive,
#SupportTickets .table-responsive {
    height: 100%;
    max-height: 100%;
}
#SalesSummary-Table,
#StudentsSummary-Table {
    margin-top: 10px;
}

#LoginForm {
    width: 35%;
}
#AppMenu {
    position: absolute;
    top: 40px;
    right: 20px;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1000;
}
#AppPopupMenu {
    left: -120px;
    background-color: #fff;
}
#AppPopupSettings {
    height: 212px;
}
#SupportPopupSettings {
    height: 135px;
}
#UserSummary {
    position: absolute;
    top: 45px;
    right: 40px;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1000;
    color: var(--client-fnt-color);
    font-size: 18px;
}
.maintenance {
    padding: 15px;
    font-size: 24px;
    color: darkred;
}

.password-container {
    position: relative
}
.password-eye {
    position: absolute;
    right: 16px;
    top: 10px;
    font-size: 20px;
    color: #777777;
}
.btn-error {
    height: 30px;
    padding: 2px;
}
.table-responsive td.btn-page,
.btn-page {
    padding-left: 4px;
    padding-right: 4px;
}

/* form */
.form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
}
.form-popup {
    padding: 15px;
}
.form-header {
    padding-bottom: 8px;
    text-transform: capitalize;
    color: var(--client-header-color);
    font-size: 24px;
}
.form-body {
    flex: 1;
    text-align: center;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: white;
    border: 1px solid #CCC;
}
.form-footer {
    flex: 1;
    text-align: center;
    margin-left: 40px;
    margin-right: 40px;
    padding-top: 15px;
    overflow: hidden;
}
.form-field {
    display: flex;
    width: calc(100% - 20px);
    margin-bottom: 4px;
}
.form-field.no-label {
    padding-left: 158px;
    margin-bottom: 4px;
}
.form-field .modified {
    color: red;
}
.form-padded-field {
    margin-left: 40px;
    width: calc(100% - 100px);
    margin-bottom: 4px;
}
.form-btn {
    margin-left: 15px;
    margin-right: 15px;
    padding-left: 15px;
    padding-right: 15px;
    border-color: var(--client-btn-border-color);
}
.form-block {
    display: flex;
    width: 100%;
    clear: both;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: #e0e0e0 1px solid;
}
.form-block-separator {
    clear: both;
    margin-bottom: 10px;
}
.form-block-label {
    text-align: left;
    font-weight: bold;
    padding-left: 5px;
    color: var(--client-btn-border-color);
}
.form-column {
    float: left;
    width: 50%;
}
.form-md-3 {
    max-width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.field-lg-label {
    float: left;
    text-align: right;
    max-width: 350px;
    display: inline-block;
    margin-right: 8px;
    padding-top: 6px;
}
.field-lg-value {
    max-width: calc(100% - 360px);
}
.field-flex {
    padding: 4px 16px;
}
.field-flex-label {
    float: left;
    display: inline-block;
    margin-right: 8px;
    padding-top: 6px;
}
.field-flex-value {
    float: right;
    max-width: calc(100% - 60px);
}
.field-md-label {
    float: left;
    text-align: right;
    width: 150px;
    display: inline-block;
    margin-right: 8px;
    padding-top: 6px;
}
.field-md-label.required:before {
    content: '*';
    color: red;
}
.field-lg-value {
    float: left;
    max-width: calc(100% - 160px);
}
.field-md-value {
    float: left;
    max-width: calc(66% - 52px);
}
.field-sm-value {
    max-width: calc(33% - 52px);
    float: left;
    margin-bottom: 4px;
}
.field-icon {
    max-height: 40px;
}

#AnalyticsForm,
#ClientForm,
#SearchForm,
#SupportForm {
    position: absolute;
    top: 100px;
    left: 50%;
    width: 80%;
    -ms-transform: translate(-50%, 0px);
    transform: translate(-50%, 0px);
    background-color: #F8F8F8;
    border: #f0f0f0 1px solid;
    border-radius: 10px;
    box-shadow: 5px 5px 5px #999;
    z-index: 1000;
}
#AnalyticsForm .form-body,
#ClientForm .form-body,
#SearchForm .form-body,
#SupportForm .form-body {
    max-height: 650px;
    padding: 15px;
}
#EditClient {
    position: absolute;
    top: 13px;
    right: 30px;
    font-size: 14px;
    color: var(--client-btn-color);
    padding-top: 0;
    padding-bottom: 0;
}

/* Progress overrides */
.progress {
    background-color: #BBBBBB;
}
.progress-bar {
    font-weight: bold;
    padding-left: 2px;
    overflow: visible;
}
.completed .progress-bar {
    background-color: var(--client-pgrs-completed);
}
.notstarted .progress-bar {
    background-color: var(--client-pgrs-notstarted);
}
.started .progress-bar {
    background-color: var(--client-pgrs-started);
}

/* Table overrides */
.table-responsive {
    position: relative;
    width: 100%;
    z-index: 1;
    margin: auto;
    line-height: 0.9em;
    max-height: 350px;
}
.full-height .table-responsive {
    max-height: 600px;
}
.table-responsive table {
    position: relative;
    width: 100%;
    margin: auto;
    border-collapse: separate;
    border-spacing: 0;
}
.table-wrap {
    position: relative;
}
.table-responsive th,
.table-responsive td {
    padding: 5px 5px;
    border: 1px solid var(--bs-table-border-color);
    background: #fff;
    vertical-align: top;
}
.table-responsive thead th {
    background: var(--client-thead-color);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}
tr.small-font th {
    font-size: 11px;
    font-weight: normal;
    text-transform: uppercase;
}
/* safari and ios need the tfoot itself to be position:sticky also */
.table-responsive tfoot,
.table-responsive tfoot th,
.table-responsive tfoot td {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1;
}

/* Scroll-bar overrides */

/* width */
.table-responsive::-webkit-scrollbar,
.scroll-y::-webkit-scrollbar {
    width: 8px;
}

/* Track */
.table-responsive::-webkit-scrollbar-track,
.scroll-y::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
}

/* Handle */
.table-responsive::-webkit-scrollbar-thumb,
.scroll-y::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 8px;
}

/* Handle on hover */
.table-responsive::-webkit-scrollbar-thumb:hover,
.scroll-y::-webkit-scrollbar-thumb:hover {
    background: #666;
}

