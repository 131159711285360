/* GENERAL */
.App {
  font-family: sans-serif;
  text-align: center;
  position: relative;
  height: 100vh;
}

.left-logo {
  height: 80px;
  width: auto;
}
.center-logo {
  height: 78px;
  width: 300px;
  object-fit: contain;
  position: absolute;
  left: 50%;
  top: 43px;
  cursor: pointer;
  transform: translate(-50%, -50%)
}

hr {
  margin: 0;
}
h3.name {
  color: darkblue;
}

.name {
  color: #707070;
  text-align: center;
}
.btn-wide {
  width: 250px;
  text-transform: uppercase;
}

summary {
  text-align: left;
  font-size: 18px;
  color: var(--client-btn-color);
}

/* React-Bootstrap style changes */
.dropdown-menu {
  max-height: 380px;
  overflow-y: scroll;
}
.react-tooltip {
  color: #fff;
  background: var(--client-clr-info);
  font-weight: bold;
}
