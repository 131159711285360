:root {
    --client-fnt-color: #120d0d;
    --client-header-color: #032580;
    --client-btn-color: #032580;
    --client-btn-inactive: #999;
    --client-btn-border-color: #3654A3;
    --client-btn-hover: #7082B3;
    --client-clr-info: #032580;
    --client-clr-warning: darkred;
    --client-clr-selected: rgba(3, 37, 128, 0.2);
    --client-pgrs-notstarted: #ca3120;
    --client-pgrs-started: #357a32;
    --client-pgrs-completed: #0d6efd;
    --client-thead-color: #e6f2ff;
}
